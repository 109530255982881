import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import './App.css';
import OrderList from './components/OrderList';
import SettingsPage from './components/SettingsPage';
import Dashboard from './components/Dashboard';
import ComponentList from './components/ComponentList';
import ProductList from './components/ProductList';
import logo from './logo.png';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import LoadingSpinner from './components/LoadingSpinner';
import { setUnauthorizedHandler } from './utils/api';
import UserManagement from './components/UserManagement';

const Layout = ({ children }) => {
  const { user, logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <>
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center">
            <img src={logo} alt="Quality SIPs Logo" className="h-8 w-auto mr-4" />
          </div>
          <h1 className="text-2xl font-bold text-green-600 absolute left-1/2 transform -translate-x-1/2">
            Ice House Order Manager
          </h1>
          <nav className="flex items-center">
            <Link to="/dashboard" className="text-gray-600 hover:text-green-600 px-3 py-2">Dashboard</Link>
            <Link to="/orders" className="text-gray-600 hover:text-green-600 px-3 py-2">Orders</Link>
            {user?.is_superuser && (
              <div className="relative">
                <button 
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="text-gray-600 hover:text-green-600 px-3 py-2 flex items-center"
                >
                  Settings
                  <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <Link 
                      to="/components" 
                      className="block px-4 py-2 text-gray-600 hover:bg-green-50 hover:text-green-600"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Components
                    </Link>
                    <Link 
                      to="/products" 
                      className="block px-4 py-2 text-gray-600 hover:bg-green-50 hover:text-green-600"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Products
                    </Link>
                    <Link 
                      to="/settings" 
                      className="block px-4 py-2 text-gray-600 hover:bg-green-50 hover:text-green-600"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Configurations
                    </Link>
                    <Link 
                      to="/users" 
                      className="block px-4 py-2 text-gray-600 hover:bg-green-50 hover:text-green-600"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      Users
                    </Link>
                  </div>
                )}
              </div>
            )}
            <button
              onClick={handleLogout}
              className="ml-4 text-red-600 hover:text-red-800 px-3 py-2"
            >
              Logout
            </button>
          </nav>
        </div>
      </header>
      <main className="container mx-auto px-4 py-8">
        {children}
      </main>
    </>
  );
};

function App() {
  const { user, loading } = useAuth();

  React.useEffect(() => {
    setUnauthorizedHandler(() => {
      window.location.href = '/login';
    });
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" replace />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Navigate to="/dashboard" replace />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <Layout>
                <OrderList />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/components"
          element={
            <PrivateRoute>
              {user?.is_superuser ? (
                <Layout>
                  <ComponentList />
                </Layout>
              ) : (
                <Navigate to="/dashboard" replace />
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute>
              {user?.is_superuser ? (
                <Layout>
                  <ProductList />
                </Layout>
              ) : (
                <Navigate to="/dashboard" replace />
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              {user?.is_superuser ? (
                <Layout>
                  <SettingsPage />
                </Layout>
              ) : (
                <Navigate to="/dashboard" replace />
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              {user?.is_superuser ? (
                <Layout>
                  <UserManagement />
                </Layout>
              ) : (
                <Navigate to="/dashboard" replace />
              )}
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
