import React, { useState, useEffect, useRef } from 'react';
import api from '../utils/api';

const SettingsPage = () => {
  const [settings, setSettings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newSetting, setNewSetting] = useState({
    category: '',
    value: '',
    is_default: false
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [editingSetting, setEditingSetting] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState('');
  const valueInputRef = useRef(null);

  useEffect(() => {
    fetchSettings();
    fetchCategories();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await api.get('/api/settings/');
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/settings/categories/');
      setCategories(Object.entries(response.data));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSetting(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingSetting) {
        await api.put(`/api/settings/${editingSetting.id}/`, newSetting);
      } else {
        await api.post('/api/settings/', newSetting);
      }
      fetchSettings();
      setNewSetting(prev => ({
        ...prev,
        value: '',
        is_default: false
      }));
      setEditingSetting(null);
    } catch (error) {
      console.error('Error saving setting:', error);
    }
  };

  const handleDelete = async (id) => {
    setDeleteConfirmation(id);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/api/settings/${deleteConfirmation}/`);
      fetchSettings();
      setDeleteConfirmation(null);
    } catch (error) {
      console.error('Error deleting setting:', error);
    }
  };

  const handleEdit = (setting) => {
    setEditingSetting(setting);
    setNewSetting({
      id: setting.id,
      category: setting.category,
      value: setting.value,
      is_default: setting.is_default
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      if (valueInputRef.current) {
        valueInputRef.current.focus();
      }
    }, 100);
  };

  const handleFilterChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-semibold mb-4">{editingSetting ? 'Edit Setting' : 'Add New Setting'}</h2>
        <div className="mb-4">
          <label htmlFor="category" className="block text-gray-700 text-sm font-bold mb-2">Category:</label>
          <select
            name="category"
            value={newSetting.category}
            onChange={handleInputChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Select a category</option>
            {categories.map(([value, display]) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="value" className="block text-gray-700 text-sm font-bold mb-2">Value:</label>
          <input
            type="text"
            name="value"
            value={newSetting.value}
            onChange={handleInputChange}
            required
            ref={valueInputRef}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {/* <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="is_default"
              checked={newSetting.is_default}
              onChange={handleInputChange}
              className="mr-2 leading-tight"
            />
            <span className="text-sm">Is Default</span>
          </label>
        </div> */}
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          {editingSetting ? 'Save Changes' : 'Add Setting'}
        </button>
        {editingSetting && (
          <button type="button" onClick={() => setEditingSetting(null)} className="ml-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Cancel
          </button>
        )}
      </form>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Existing Settings</h2>
        <div className="flex items-center">
          <label htmlFor="categoryFilter" className="mr-2 text-gray-700 text-sm font-bold">Filter by Category:</label>
          <select
            name="categoryFilter"
            value={categoryFilter}
            onChange={handleFilterChange}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">All Categories</option>
            {categories.map(([value, display]) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Category</th>
              <th className="py-3 px-6 text-left">Value</th>
              {/* <th className="py-3 px-6 text-center">Is Default</th> */}
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
            {settings
              .filter(setting => categoryFilter === '' || setting.category === categoryFilter)
              .map((setting) => (
                <tr key={setting.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap font-medium">
                    {setting.category_display}
                  </td>
                  <td className="py-3 px-6 text-left font-medium">{setting.value}</td>
                  {/* <td className="py-3 px-6 text-center">
                    <span className={`${setting.is_default ? 'bg-green-200 text-green-600' : 'bg-red-200 text-red-600'} py-1 px-3 rounded-full text-xs`}>
                      {setting.is_default ? 'Yes' : 'No'}
                    </span>
                  </td> */}
                  <td className="py-3 px-6 text-center">
                    <button
                      onClick={() => handleEdit(setting)}
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(setting.id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Confirmation</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete this setting?
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  onClick={confirmDelete}
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => setDeleteConfirmation(null)}
                  className="mt-3 px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
