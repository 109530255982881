import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useForm } from 'react-hook-form';

const ComponentList = () => {
  const [components, setComponents] = useState([]);
  const [editingComponent, setEditingComponent] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, componentId: null });
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: '',
      cost: '',
      markup: 0
    }
  });

  useEffect(() => {
    fetchComponents();
  }, []);

  const fetchComponents = async () => {
    try {
      const response = await api.get('/api/components/');
      setComponents(response.data);
    } catch (error) {
      console.error('Error fetching components:', error);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (editingComponent) {
        await api.put(`/api/components/${editingComponent.id}/`, data);
        setEditingComponent(null);
      } else {
        await api.post('/api/components/', data);
      }
      reset();
      fetchComponents();
    } catch (error) {
      console.error('Error saving component:', error);
    }
  };

  const handleEdit = (component) => {
    setEditingComponent(component);
    setValue("name", component.name);
    setValue("cost", component.cost);
    setValue("markup", component.markup);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/api/components/${id}/`);
      fetchComponents();
      setDeleteConfirmation({ show: false, componentId: null });
      reset(); // Clear the form
      setEditingComponent(null); // Reset editing state
    } catch (error) {
      console.error('Error deleting component:', error);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="flex justify-between items-center p-6 bg-gray-50 border-b">
        <h2 className="text-2xl font-bold text-[#006F3E]">Components</h2>
        <button
          onClick={handleSubmit(onSubmit)}
          className="bg-[#006F3E] hover:bg-[#005a32] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          {editingComponent ? 'Update Component' : 'Add Component'}
        </button>
      </div>
      <div className="p-6">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-6">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <input
              {...register("name", { required: "Name is required" })}
              type="text"
              placeholder="Component Name"
              className="flex-1 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
            />
            <div className="flex-1 relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
              <input
                {...register("cost", { 
                  required: "Cost is required",
                  valueAsNumber: true,
                  min: { value: 0, message: "Cost must be positive" }
                })}
                type="number"
                step="0.01"
                placeholder="Cost"
                className="w-full pl-7 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
              />
            </div>
            <div className="flex-1 relative">
              <input
                {...register("markup", { 
                  required: "Markup is required",
                  valueAsNumber: true,
                  min: { value: 0, message: "Markup must be positive" }
                })}
                type="number"
                step="0.1"
                placeholder="Markup"
                className="w-full pr-8 px-3 py-2 border border-[#006F3E] rounded-md shadow-sm focus:outline-none focus:ring-[#006F3E] focus:border-[#005a32] bg-white text-gray-700 placeholder-gray-400"
              />
              <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500">%</span>
            </div>
          </div>
          {(errors.name || errors.cost || errors.markup) && (
            <div className="text-red-500 mt-2">
              {errors.name?.message || errors.cost?.message || errors.markup?.message}
            </div>
          )}
        </form>
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#e6f3ee] border-b border-[#b3d9c8]">
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Name</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Cost</th>
                  <th className="px-6 py-4 text-left text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Markup</th>
                  <th className="px-6 py-4 text-center text-sm font-semibold text-[#006F3E] uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {components.map((component) => (
                  <tr key={component.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">{component.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">${parseFloat(component.cost).toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-base text-gray-900">{parseFloat(component.markup).toFixed(1)}%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => handleEdit(component)}
                          className="bg-[#006F3E] hover:bg-[#005a32] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                        >
                          Edit
                        </button>
                        {deleteConfirmation.show && deleteConfirmation.componentId === component.id ? (
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={() => handleDelete(component.id)}
                              className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                            >
                              Confirm
                            </button>
                            <button
                              onClick={() => setDeleteConfirmation({ show: false, componentId: null })}
                              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => setDeleteConfirmation({ show: true, componentId: component.id })}
                            className="bg-[#e57373] hover:bg-[#ef5350] text-white font-medium py-1 px-3 rounded text-sm transition duration-150 ease-in-out"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {components.length === 0 && (
            <div className="text-center py-4 text-base text-gray-500">No components found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponentList;
