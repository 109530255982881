import React, { useState } from 'react';
import api from '../utils/api';

const PDFUploader = ({ orderId, currentBlueprint, currentBlueprintName, onUploadComplete }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!orderId) {
            setError('Please save the order first');
            return;
        }

        // Validate file type
        if (!file.type.includes('pdf')) {
            setError('Please upload PDF files only');
            return;
        }

        // Validate file size (50MB max)
        if (file.size > 50 * 1024 * 1024) {
            setError('File size must be less than 50MB');
            return;
        }

        setIsUploading(true);
        const formData = new FormData();
        formData.append('blueprint', file);

        try {
            const response = await api.post(
                `/api/orders/${orderId}/upload_blueprint/`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setUploadProgress(percentCompleted);
                    },
                }
            );
            
            onUploadComplete && onUploadComplete(response);
            setUploadProgress(0);
            setError(null);
            
        } catch (error) {
            console.error('Upload failed:', error);
            setError(`Failed to upload: ${error.message}`);
        } finally {
            setIsUploading(false);
        }
    };

    const handlePreview = () => {
        if (orderId) {
            // Use environment variable for the API URL
            const apiUrl = process.env.REACT_APP_API_URL || 'https://test.rocksoliddata.solutions';
            window.open(`${apiUrl}/api/orders/${orderId}/serve-blueprint/`, '_blank');
        }
    };

    const removeFile = async (e) => {
        e.preventDefault();
        
        if (!orderId) return;

        try {
            await api.delete(
                `/api/orders/${orderId}/remove-blueprint/`
            );
            
            onUploadComplete && onUploadComplete({
                data: {
                    blueprint: null,
                    blueprint_filename: null
                }
            });
            
        } catch (error) {
            console.error('Remove file error:', error);
            setError(`Failed to remove file: ${error.message}`);
        }
    };

    return (
        <div className="w-full">
            <div className="mt-1">
                {currentBlueprint ? (
                    <div className="px-6 pt-5 pb-6 border-2 border-[#006F3E] rounded-md">
                        <div className="flex items-center justify-between py-2">
                            <span className="truncate">
                                {currentBlueprintName}
                            </span>
                            <div className="flex items-center space-x-2">
                                {isUploading && (
                                    <div className="w-24 bg-gray-200 rounded-full h-2.5 mr-2">
                                        <div
                                            className="bg-[#006F3E] h-2.5 rounded-full"
                                            style={{ width: `${uploadProgress}%` }}
                                        ></div>
                                    </div>
                                )}
                                <button
                                    onClick={handlePreview}
                                    type="button"
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    Preview
                                </button>
                                <button
                                    onClick={removeFile}
                                    disabled={isUploading}
                                    type="button"
                                    className="text-red-500 hover:text-red-700 disabled:text-gray-400"
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center px-6 pt-5 pb-6 border-2 border-[#006F3E] border-dashed rounded-md min-h-[200px]">
                        <svg 
                            className="mx-auto h-12 w-12 text-gray-400" 
                            stroke="currentColor" 
                            fill="none" 
                            viewBox="0 0 48 48"
                        >
                            <path 
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="flex flex-col items-center">
                            <label 
                                htmlFor="file-upload" 
                                className="relative cursor-pointer bg-white rounded-md font-medium text-[#006F3E] hover:text-[#005c34] focus-within:outline-none"
                            >
                                <span>Upload PDF</span>
                                <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    accept=".pdf,application/pdf"
                                    className="sr-only"
                                    onChange={handleFileChange}
                                    disabled={isUploading}
                                />
                            </label>
                            <p className="text-xs text-gray-500 mt-1">
                                Click to select a PDF file
                            </p>
                        </div>
                    </div>
                )}
                
                {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                )}
            </div>
        </div>
    );
};

export default PDFUploader;
